module.exports = {
    siteMetadata: {
      title: `Outsmart Insight`,
      description: `We crowdsource scientists to help R&D companies get smarter about next-generation technologies.`,
      author: `@outsmartinsight`,
      siteUrl: 'https://www.outsmartinsight.com',
      twitterHandle: '@outsmartinsight',
      address: 'Outsmart HQ, London, UK',
      telephoneNo: '(+44) 020 664 8293',
      email: {
        contact: 'contact@outsmart.com'
      },
      linkedInUrl: 'https://www.linkedin.com/company/outsmart-insights-ltd'
    },
    customerStories: {
        pageSize: 12
    },
    ourInsights: {
        pageSize: 12
    },
    projects: {
        pageSize: 18
    },
    pages: {
      contact: '/contact',
      cookiePolicy: '/cookie-policy',
      newsletter: '/newsletter',
      privacyPolicy: '/privacy-policy',
      termsConditions: '/terms-conditions'
    },
    prismic: {
      accessToken: process.env.PRISMIC_ACCESS_TOKEN,
      repo: process.env.GATSBY_PRISMIC_REPO_NAME
    },
    newsletter: {
      activeCampaign: {
        companySubDomain: "",
        formId: ""
      }
    }
}