// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-flat-index-js": () => import("./../../../src/pages/flat/index.js" /* webpackChunkName: "component---src-pages-flat-index-js" */),
  "component---src-pages-flat-insights-article-js": () => import("./../../../src/pages/flat/insights-article.js" /* webpackChunkName: "component---src-pages-flat-insights-article-js" */),
  "component---src-pages-flat-insights-js": () => import("./../../../src/pages/flat/insights.js" /* webpackChunkName: "component---src-pages-flat-insights-js" */),
  "component---src-pages-flat-service-js": () => import("./../../../src/pages/flat/service.js" /* webpackChunkName: "component---src-pages-flat-service-js" */),
  "component---src-pages-flat-widgets-js": () => import("./../../../src/pages/flat/widgets.js" /* webpackChunkName: "component---src-pages-flat-widgets-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-customer-stories-article-js": () => import("./../../../src/templates/customer-stories-article.js" /* webpackChunkName: "component---src-templates-customer-stories-article-js" */),
  "component---src-templates-customer-stories-index-js": () => import("./../../../src/templates/customer-stories-index.js" /* webpackChunkName: "component---src-templates-customer-stories-index-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/generic-page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-our-insight-article-js": () => import("./../../../src/templates/our-insight-article.js" /* webpackChunkName: "component---src-templates-our-insight-article-js" */),
  "component---src-templates-our-insight-index-js": () => import("./../../../src/templates/our-insight-index.js" /* webpackChunkName: "component---src-templates-our-insight-index-js" */),
  "component---src-templates-our-insight-tag-index-js": () => import("./../../../src/templates/our-insight-tag-index.js" /* webpackChunkName: "component---src-templates-our-insight-tag-index-js" */),
  "component---src-templates-project-index-js": () => import("./../../../src/templates/project-index.js" /* webpackChunkName: "component---src-templates-project-index-js" */),
  "component---src-templates-service-article-js": () => import("./../../../src/templates/service-article.js" /* webpackChunkName: "component---src-templates-service-article-js" */),
  "component---src-templates-service-index-js": () => import("./../../../src/templates/service-index.js" /* webpackChunkName: "component---src-templates-service-index-js" */),
  "component---src-templates-solution-article-js": () => import("./../../../src/templates/solution-article.js" /* webpackChunkName: "component---src-templates-solution-article-js" */),
  "component---src-templates-solution-index-js": () => import("./../../../src/templates/solution-index.js" /* webpackChunkName: "component---src-templates-solution-index-js" */)
}

